<template>
    <div>
        <section class="hero is-medium is-dark has-background">
            <img :src="header_image" class="is-transparent hero-background" />
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-1 is-size-2-mobile">
                        Teamevents
                    </h1>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="content is-medium">
                    <h1 class="title is-2 is-bold">Begleiteter Workshop</h1>
                    <hr>
                    <p class="subtitle is-4">
                        Das gemeinsame Aufbauen von Dominosteinen eignet sich ideal für Ihren nächsten Firmenevent. 
                        Schlüsselqualifikationen wie Gruppenzusammenhalt, Kommunikation, im Team etwas planen und realisieren werden hier auf die Probe gestellt.
                        Entdecken Sie auf spielerische und unterhaltsame Weise die Welt der Kettenreaktionen mit Dominosteinen.
                    </p>
                </div>

                <nav class="level">
                    <div class="level-item has-text-centered">
                        <div class="is-medium">
                        <b-icon icon="clock-time-eight-outline"
                                    size="is-large"
                                    type="is-primary">
                        </b-icon>
                        <p class="heading">Dauer</p>
                        <p class="title">2h - 8h</p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                        <b-icon icon="map-marker"
                                        size="is-large"
                                        type="is-primary">
                                    </b-icon>
                        <p class="heading">Location</p>
                        <p class="title">Bei Ihnen</p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <b-icon icon="account-group-outline"
                                    size="is-large"
                                    type="is-primary"></b-icon>
                        <p class="heading">Gruppengrösse</p>
                        <p class="title">5 - 100</p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <b-icon icon="circle-multiple-outline"
                                    size="is-large"
                                    type="is-primary"></b-icon>
                        <p class="heading">Preis</p>
                        <p class="title">ab CHF 900</p>
                        </div>
                    </div>
                </nav>
            </div></section>
            <section class="hero is-small is-primary">
                <div class="hero-body">
                    <div class="container">
                        <!--<p class="title is-4">
                            Angebote buchen im Online-Shop
                        </p>-->
                        <router-link :to="{name: 'shop', hash: '#workshops'}" class="button is-medium is-white is-outlined">Angebot jetzt buchen</router-link>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="container">
                <div class="content is-medium">
                    <div class="columns">
                        <div class="column is-5">
                            <p class="subtitle is-4">
                                Im Zentrum eines jeden Workshops steht das gemeinsame Aufbauen einer zusammenhängenden Dominokettenreaktion welche am Ende zu Fall gebracht wird.
                                Dieses Gemeinschaftsprojekt fördert folgende Schlüsselqualifikationen:
                                <br>
                            <ul>
                                <li>Teamwork</li>
                                <li>Kommunikation</li>
                                <li>Kooperation und Koordination</li>
                                <li>Konfliktfähigkeit</li>
                                <li>Umgang mit Erfolg und Scheitern</li>
                                <li>Gruppenzusammenhalt</li>
                                <li>Zielorientiertes Arbeiten</li>
                                <li>Kreativität und Selbstverwirklichung</li>
                            </ul>
                            </p>
                        </div>
                        <div class="column is-7">
                            <div>
                            <b-carousel :indicator-inside="false"  indicator-custom style="margin-bottom: 10px;">
                                <b-carousel-item v-for="(pic, i) in business_pictures" :key="i">
                                    <span class="image">
                                        <img :src="pic">
                                    </span>
                                </b-carousel-item>
                                <template #indicators="props">
                                    <span class="al image">
                                        <img :src="business_pictures[props.i]">
                                    </span>
                                </template>
                            </b-carousel>
                            </div>
                        </div>
                    </div>
                    <!--<div class="notification is-primary">
                        <h3 class="title is-4">Möchten Sie an Ihrer Firma ein Workshop durchführen?</h3>
                        <p>Haben wir Ihr Interesse für einen Workshop mit Dominosteinen geweckt?</p>
                        <router-link :to="{name: 'shop'}" class="button is-medium is-white">Angebot jetzt buchen</router-link>
                    </div>-->
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="content is-medium">
                    <div class="content is-medium">
                    <h1 class="title is-2 is-bold">Selbstständig organisierter Workshop</h1>
                    <hr>
                </div>
                    <p class="subtitle is-4">
                        Leiten Sie Ihren eigenen Workshop und nutzen Sie unser umfangreiches Angebot. 
                        Sämtliche Materialien, Hilfsmittel und Instruktionen werden von uns zu Verfügung gestellt. 
                        Dabei profitieren Sie von attraktiven Konditionen.
                    </p>
                </div>
                <nav class="level">
                    <div class="level-item has-text-centered">
                        <div class="is-medium">
                        <b-icon icon="package-variant-closed"
                                    size="is-large"
                                    type="is-primary">
                        </b-icon>
                        <p class="heading">Dominosteine Mieten</p>
                        <p class="title">ab 5 Boxen</p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                        <b-icon icon="truck-delivery"
                                        size="is-large"
                                        type="is-primary">
                                    </b-icon>
                        <p class="heading">Lieferung</p>
                        <p class="title">Optional</p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <b-icon icon="laptop"
                                    size="is-large"
                                    type="is-primary"></b-icon>
                        <p class="heading">SDE Portal</p>
                        <p class="title">Unterlagen</p>
                        </div>
                    </div>
                    <div class="level-item has-text-centered">
                        <div>
                            <b-icon icon="circle-multiple-outline"
                                    size="is-large"
                                    type="is-primary"></b-icon>
                        <p class="heading">Preis</p>
                        <p class="title">ab CHF 250</p>
                        </div>
                    </div>
                </nav>
            </div>
        </section>
        <section class="hero is-small is-primary">
                <div class="hero-body">
                    <div class="container">
                        <router-link :to="{name: 'mieten', params: {access_type: 'company'}}" class="button is-medium is-white is-outlined">Kosten direkt berechnen</router-link>
                    </div>
                </div>
            </section>
            <section class="section">
                <div class="container">
                    <div class="content is-medium">
                        <div class="columns">
                            <div class="column is-7">
                                <div>
                                    <b-carousel :indicator-inside="false"  indicator-custom style="margin-bottom: 10px;">
                                        <b-carousel-item v-for="(pic, i) in custom_pictures" :key="i">
                                            <span class="image">
                                                <img :src="pic">
                                            </span>
                                        </b-carousel-item>
                                        <template #indicators="props">
                                            <span class="al image">
                                                <img :src="custom_pictures[props.i]">
                                            </span>
                                        </template>
                                    </b-carousel>
                                </div>
                            </div>
                            <div class="column is-5">

                                <p class="subtitle is-4">Bei einem selbstständig organisierten Workshop erhalten Sie exklusiven Zugang zu unserem <a href="https://swissdominoeffect.com/portal" target="_blank"> SDE Portal</a>.
                                Dieses beinhaltet folgende Module:</p>
                                <ul>
                                    <li>Ausführliche Anleitungen zu sämtlichen Techniken unterstützt durch Bilder & Videos</li>
                                    <li>Sämtliche Unterlagen zur Durchführung eines Workshops</li>
                                    <li>Diverse Vorlagen zum Aufbau von Konstrukten</li>
                                    <li>Feldplanung von eigenem Firmenlogo</li>
                                </ul>
                                <div class="notification is-light">Der Zugang ist kostenpflichtig und dauert von der
                                    gegenseitigen Bestätigung bis zum Ende einer Vermietung. Somit haben Sie als Organisator*in
                                    ausreichend Zeit, mit den benötigten Materialien Ihr Projekt vorzubereiten.</div>
                                <div class="buttons is-centered">
                                    <router-link :to="{name: 'mieten', params: {access_type: 'company'}}" class="button is-medium  is-primary">Kosten direkt berechnen</router-link>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Workshop über Kettenreaktionen mit Dominosteinen",
    meta: [
      {
        name: "description",
        content:
          "Wie baut man coole Kettenreaktionen? In unseren Workshops zeigen wir die wichtigsten Tricks und unterstützen beim Bau eigener Kleinprojekte.",
      },
      {
        name: "keywords",
        content:
          "Dominobauen lernen, Unterstützung, Tipps und Tricks, Dominosteine",
      },
    ],
  },
  data: function () {
    return {
      header_image:
        "https://swissdominoeffect.com/img/headers/workshop_domino.png",
      business_pictures: [],
      custom_pictures: [],
    };
  },
  created() {
    let directoryBusiness = require.context(
      "@/assets/img/workshop/business",
      true
    );
    directoryBusiness.keys().forEach((element) => {
      this.business_pictures.push(directoryBusiness(element));
    });
    let directoryRent = require.context("@/assets/img/workshop/rent", true);
    directoryRent.keys().forEach((element) => {
      this.custom_pictures.push(directoryRent(element));
    });
  },
};
</script>
<style>
.is-active .al img {
  filter: grayscale(0%);
}

.al img {
  filter: grayscale(100%);
}

li {
  padding-bottom: 15px;
}
</style>